body {
  background: $color--light;
  font-size: $font--base-size;
  font-family: $font--base-family;
  color: $color--font-base;
  font-weight: 300;
  min-height: 100vh;
}

.row {
  padding: 0 !important;

  @include for-size(md) {
    padding: 0 2rem !important;
  }
}
