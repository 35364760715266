.hero-banner {
	@extend %block-flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	position: relative;
	overflow: hidden;

	@include for-size(lg) {
		width: 100%;
		min-height: 525px;
	}

	&.reverse {
		.bg-image, .video-box {
			order: 2;
		}
	}

	.bg-image, .video-box {
		width: 100%;
		position: relative;
		overflow: hidden;

		@include for-size(lg) {
			width: 100%;
			height: 525px !important;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0; 
			object-fit: cover;
		}

		img, video {
			@extend %block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.hero-banner--inner {
		@extend %block-flex;
		justify-content: center;
		align-items: flex-end;
		padding-top: 0;
		padding-bottom: 0;

		@include for-size(lg) {
			padding-top: $spacing--large;
			padding-bottom: $spacing--large;
		}

		.row {
			padding: 0 !important;

			@include for-size(lg) {
				padding-right: 2rem;
				padding-left: 2rem;
			}

			.col {
				padding: 0;

				@include for-size(lg) {
					padding: $spacing--medium;
				}
			}
		}

		.slide-content {
			@extend %block;
			background: rgba(57,57,57,0.6);
			padding: $spacing--large $spacing--medium;
			@include backdrop-filter(blur(10px));
			@include radius(0);

			@include for-size(lg) {
				padding: $spacing--large;
				@include radius($radius--small);
			}

			.tag, .headline, .text {
				color: $color--light;
			}

			p.tag {
				display: inline;
				width: auto;
				font-size: 0.8rem;
				font-weight: 700;
				letter-spacing: 0.05rem;
				background: $color--primary;
				padding: 0.25rem 0.5rem;
				@include radius(3px);
			}

			.headline {
				h3 {
					font-size: 1.45rem;
					line-height: 1.15;
					font-weight: 900;
					letter-spacing: 0.05rem;

					@include for-size(sm) {
						font-size: 1.7rem;
					}

					@include for-size(md) {
						font-size: 1.95rem;
					}

					@include for-size(lg) {
						font-size: 2.2rem;
					}
				}
			}
		}
	}
}