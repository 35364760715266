.ce-button-group {
	@extend %block;
	
	.neos-contentcollection {
		display: flex;
		align-items: center;
		flex-grow: 1;
	}
	
	&.align-left {
		.neos-contentcollection {
			justify-content: flex-start;
		}
	}
	
	&.align-right {
		.neos-contentcollection {
			justify-content: flex-end;
		}
	}
	
	&.align-center {
		.neos-contentcollection {
			justify-content: center;
		}
	}
	
	&.align-between {
		.neos-contentcollection {
			justify-content: space-between;
		}
	}
	
	&.align-around {
		.neos-contentcollection {
			justify-content: space-around;
		}
	}
}

.ce-button {
	margin-right: 0.75rem;
	flex-basis: auto;
	
	a {
		display: block;
		text-align: center;
		background: $color--light;
		padding: 0.5rem 0.75rem;
		border: 3px solid $color--primary;
		@include radius($radius--small);
		font-size: 1rem;
		font-weight: 700;
		@include transition(all 0.25s $easing--in-out);

		@include for-size(md) {
			font-size: 1.05rem;
		}

		&:hover {
			background: $color--primary;
			color: $color--light;
			@include transition(all 0.25s $easing--in-out);
		}
	}
}

